<script>
  // @ts-check
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { listCampaignIdsFromState } from "$lib/utilities/formFields";
  import {
    buildSrc,
    postToSalesforce,
    srcSetFromWidths,
    sizesForBreakpoints,
    SRCSET_WIDTHS
  } from "$lib/utilities";
  import { BREAK_POINTS } from "$lib/utilities/constants";
  import { getContext, onMount } from "svelte";
  import { GridContainer, NewsLetter } from "@ObamaFoundation/of-design-system";
  import { SALESFORCE_CONSTANTS } from "$lib/utilities/constants";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = {};
  const sizes = sizesForBreakpoints(widthsAt);

  /** The type of layout under the featured layout
   * @type { "vertical" | "horizontal" }
   */
  export let layoutVariant = "horizontal";

  const Constants = {
    formSubmitErrorMessage: "Something went wrong..."
  };

  let selectedNewsletters = getContext("selectedNewsletters");
  let isNewsletterListChild = !!$selectedNewsletters;

  export let theme;
  export let title;
  export let description;
  export let titleConfirmationPage;
  export let subTitleConfirmationPage;
  export let descriptionConfirmationPage;
  export let image;
  export let formProps;
  export let formFields;
  export let campaignId;
  export let result = {};
  export let newsLetterListNoCampaignErrorMessage = "";

  let screenWidth = 0;
  $: shouldUseMobileAsset = screenWidth < BREAK_POINTS.md && image?.mobileImageAsset;

  $: featuredImage = {
    src: buildSrc(shouldUseMobileAsset ? image?.mobileImageAsset : image?.imageAsset),
    alt: image?.altText,
    srcset:
      !ignoreContentfulImgResize && image?.imageAsset.fields.file
        ? srcSetFromWidths(
            shouldUseMobileAsset
              ? image?.mobileImageAsset.fields.file.url
              : image?.imageAsset.fields.file.url,
            SRCSET_WIDTHS,
            resizeParams
          )
        : null,
    sizes,
    theme: image?.theme
  };

  let formSubmitErrorMessage = "";
  let submitted = false;
  let isSubmitting = false;

  onMount(() => {
    if ($page?.url.searchParams.get("success") === "1") {
      submitted = true;
    }
  });

  async function handleSubmit(ev) {
    formSubmitErrorMessage = "";
    const formValues = ev.detail;
    let campaignIds = isNewsletterListChild
      ? listCampaignIdsFromState($selectedNewsletters)
      : campaignId;
    if (campaignIds.length === 0) {
      // No campaignIds selected or protected
      formSubmitErrorMessage = newsLetterListNoCampaignErrorMessage;
      return;
    }
    let hasBeenSubmitted = false;
    try {
      isSubmitting = true;
      result = await postToSalesforce({
        pageUrl: $page?.url?.href,
        campaignIDs: campaignIds,
        firstname: formValues[SALESFORCE_CONSTANTS.fieldNames.firstName],
        lastname: formValues[SALESFORCE_CONSTANTS.fieldNames.lastName],
        email: formValues[SALESFORCE_CONSTANTS.fieldNames.email]
      });

      if (result?.status === "success") {
        hasBeenSubmitted = true;
        submitted = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error submitting form");
      // Sentry.captureException(err);
    } finally {
      isSubmitting = false;
      if (!hasBeenSubmitted) {
        formSubmitErrorMessage = Constants.formSubmitErrorMessage;
      }
    }
  }

  const isContainerized = !!getContext("containerized");
</script>

<svelte:window bind:innerWidth={screenWidth} />

{#if isContainerized}
  <NewsLetter
    id={campaignId}
    {theme}
    class={className}
    {title}
    titleTag="h2"
    {description}
    {featuredImage}
    {titleConfirmationPage}
    {layoutVariant}
    {subTitleConfirmationPage}
    {formSubmitErrorMessage}
    {descriptionConfirmationPage}
    {formProps}
    {formFields}
    isFormSubmitSuccess={submitted}
    {isSubmitting}
    cta={{ text: "View all newsletters", url: "/newsletters" }}
    onSubmit={handleSubmit}
  />
{:else}
  <GridContainer containerClass={className}>
    <NewsLetter
      id={campaignId}
      {theme}
      {title}
      titleTag="h2"
      {description}
      {featuredImage}
      {titleConfirmationPage}
      {layoutVariant}
      {subTitleConfirmationPage}
      {formSubmitErrorMessage}
      {descriptionConfirmationPage}
      {formProps}
      {formFields}
      isFormSubmitSuccess={submitted}
      {isSubmitting}
      cta={{ text: "View all newsletters", url: "/newsletters" }}
      onSubmit={handleSubmit}
    />
  </GridContainer>
{/if}
