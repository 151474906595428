<script>
  // @ts-check
  import ItemPreviewList from "$lib/components/ItemPreviewList.svelte";
  import {
    getPaginatedItemPreviewListItems,
    getThemedItemIds
  } from "$lib/utilities/contentTypes/itemPreviewList";
  // eslint-disable-next-line @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { filterItemsStore } from "$lib/stores";
  import { onDestroy } from "svelte";
  import {
    filterOutInactiveFilterGroups,
    getTotalNumberOfSelectedFilters
  } from "$lib/utilities/filters";

  const Constants = /** @type {const} */ ({
    pressReleasesPath: "/press-releases",
    maximumItemsInPressReleasesPage: 8
  });

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;
  export let itemsPerPage = 36;

  const urlPathName = $page?.url?.pathname;
  /** @type { () => number } */
  const getCurrentItemsPerPage = () => {
    if (
      urlPathName === `${Constants.pressReleasesPath}/` ||
      urlPathName === Constants.pressReleasesPath
    ) {
      return Constants.maximumItemsInPressReleasesPage;
    }
    return itemsPerPage;
  };

  // Tag automation gets the preference
  const isPopulatedByPathOrTag = !!data.fields?.itemsSource || !!data.fields?.pathQuery;
  const itemsData = isPopulatedByPathOrTag ? data.children : data.fields?.listItems;
  const itemsFormFactor = data.fields.itemsFormFactor;

  const themedItemIds = getThemedItemIds(data.fields?.themedItems);
  const featuredItems = data.fields?.featuredItems ?? [];
  const excludedItems = data.fields?.excludedItems ?? [];
  const sourceTagItemId = data.fields?.itemsSource ? data.fields?.itemsSource.sys?.id : null;

  const currentItemsPerPage = getCurrentItemsPerPage();

  let filteredItems = [];

  $: currentUrl = $page?.url;

  let screenWidth = 0;

  $: paginatedItems =
    getPaginatedItemPreviewListItems({
      itemsData: filteredItems,
      itemsFormFactor,
      themedItemIds,
      featuredItems,
      excludedItems,
      sourceTagItemId,
      itemsPerPage: currentItemsPerPage,
      isPopulatedByPathOrTag,
      screenWidth
    }) ?? [];

  /**
   * TODO: Refactor this history logic in the future ticket for back button behavior
   */
  const setHistory = () => {
    const newUrl = new URL(currentUrl.href);

    newUrl.searchParams.set("ipl", data.sys.id);
    newUrl.searchParams.set("page", "1");

    history?.pushState(
      {
        ipl: data.sys.id,
        page: 1
      },
      "",
      newUrl
    );
  };

  const unsubscribe = filterItemsStore.subscribe((filters) => {
    if (getTotalNumberOfSelectedFilters(filters) === 0) {
      filteredItems = [...itemsData];
    } else {
      filteredItems = [...itemsData].filter((item) => {
        return filterOutInactiveFilterGroups(filters).every((filterGroup) => {
          return filterGroup.bodyProps.options.some((option) => {
            return (
              option.isChecked &&
              item.contentTags.some((tag) => {
                return option.label === tag.fields.name || option.label === tag.name;
              })
            );
          });
        });
      });
    }

    if (currentUrl) {
      setHistory();
    }
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<svelte:window bind:innerWidth={screenWidth} />

<ItemPreviewList
  class={className}
  {itemsFormFactor}
  items={paginatedItems.length ? paginatedItems : []}
  iplId={data.sys?.id}
/>
